:root {
    --main: #fcb721;
    --sub: #FD8628;
    --esn: #76c047;
    --cn: #1794d2;
    --pn: #a3318a;
    --kn: #e72c6a;
    --se: #010101;
    --sa: #053043;
    --grey: #efefef;
    --lightgrey: #f7f7f7;
    --grey-5: #f1f2f2;
    --grey-10: #e6e7e8;
    --grey-20: #d1d3d4;
    --grey-30: #bcbec0;
    --grey-50: #939598;
    --grey-80: #58595b;
    --grey-90: #414042;
    --grey-100: #231f20;
    --error: #ff6e44;
    --success: #4bb172;
    --warning: #f1ab3e;
    --info: #29B8FF;
    --facebook: #3b5998;
    --googleplus: #dd4b39;
    --linkedin: #0077b5;
    --youtube: #ff0000;
    --twitter: #1da1f2;
    --instagram: #405de6;
    --ads: #fdb913;
    --video: #0059c7;
    --cn: #1394d2;
    --pn: #9e3d97;
    --website: #080808;
    --eshop: #62b331;
    --newsletter: #00384e;
}
body,
p,
input, 
textarea {
    font-family: 'Roboto', sans-serif;
}
h1,
h2,
h3,
h4 {
    font-family: 'Roboto Slab', serif;
}